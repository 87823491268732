//this file contains all styles for the partnermarket: SupportRequests

.new-support-request__form {
  display: flex;
  gap: 1rem;
  flex-grow: 1;

  .col {

    &:first-child {
      flex-grow: unset;
      flex-basis: 35%;
    }

    &:nth-child(2) {
      @include card;
      padding: 1.5rem;
    }
  }
}

.supporter-role-card {
  font-size: 1.25rem;
  font-family: $font-heading;
  font-weight: 700;
}

// The actual style of SupportRequestCard (--default, --light, ...)
// is determined in SupportRequestCard.tsx using the value of SUPPORT_REQUEST_CARD_STYLE env variable
@mixin support-request-card {

  // limits width in case supportRequestCard is not displayed in grid
  max-width: 40rem;
  height: 100%;
  min-height: 300px;
  border-radius: 0.625rem;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 8rem;
    z-index: -1;
    border-radius: 0.625rem 0.625rem 0 0;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    border-radius: 0.625rem;
  }

  .support-request-card {

    &__front {

      &__body {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        flex-direction: column;

        &__supporter-role {
          margin-left: 0px;
          text-align: left;
          font-weight: bold;
        }

        // background-color is set within the code, changing the opacity, to have a card color depending on chosen SupporterRole
        // @see: SupportRequestCard
        &__request-title {
          &>h3 {
            font-family: $font-heading;
          }
        }
      }

      &__content {
        font-weight: bold;
      }

      &__data-and-actions {
        font-weight: normal;
      }

      &__supportoffer-popup-button {
        text-align: center;
      }
    }
  }

  &__back {
    &__body {
      display: flex;
      flex-direction: column;
    }
  }
}

.support-request-card {
  &--default {
    @include support-request-card;

    padding: 0 1rem;

    &:before {
      background-image: $gradient-of-color-secondary;
    }

    &:after {
      background-image: $gradient-of-color-secondary;
    }

    .support-request-card {

      &__front {

        &__body {
          padding: 3rem 1rem 2rem 1rem;

          &__supporter-role {
            position: absolute;
            top: -0.8rem;
            left: -0.625rem;
            background-color: $color-gray-lighter;
            padding: 10px 1rem 0.5rem 2rem;
            box-shadow: inset 0 0 0 1px $color-gray-medium;
            height: 42px;
            font-family: $font-heading;
            font-size: 1rem !important;
            font-weight: bold;

            &::before {
              content: "";
              width: 0;
              height: 0;
              border-top: 10px solid $color-gray-light;
              border-left: 10px solid transparent;
              top: 100%;
              position: absolute;
              left: -1px;
            }

            &::after {
              content: "";
              background-image: url("/assets/img/partner-market/flag-after.svg");
              width: 18px;
              height: 100%;
              position: absolute;
              top: 0;
              right: -10px;
            }
          }

          &__request-title {
            margin: auto;

            &>h3 {
              font-size: 1rem !important;
              font-weight: bold;
            }
          }

          &__supporter-role-picture {
            text-align: center;
            margin: auto;
          }

          &>hr {
            border-top: 1px solid $color-gray;
            width: 35%;
            margin-left: 0;
          }
        }
      }

      &__back {
        &__body {
          padding: 3rem 1rem 2rem 1rem;
          gap: 0.5rem;
        }
      }
    }

  }

  &--light {
    @include support-request-card;
    padding: 0 0.5rem;

    &:before {
      background-image: radial-gradient(circle at top left, $color-white, $color-white);
    }

    &:after {
      background-image: radial-gradient(circle at top left, $color-white, $color-white);
    }

    .support-request-card {
      &__front {
        &__body {
          padding: 1rem 1rem 2rem 1rem;

          &__supporter-role-and-title {
            display: flex;
            gap: 0.5rem;
            align-items: baseline;
            position: relative;
            margin-left: 0;
            text-align: left;
          }

          &__supporter-role {
            position: relative;
            background-color: $color-gray-lighter;
            padding: 0.2rem 0.5rem;
            border-radius: 1rem;
            font-family: $font-heading;
            margin-right: 0.5rem;
            font-size: 0.8rem !important;
          }

          &__request-title {
            display: flex;
            gap: 0.5rem;
            align-items: baseline;

            &>h3 {
              font-size: 0.8rem !important;
              font-weight: normal;
            }
          }

          &__content {
            margin-top: 1rem;
          }

          &__data-and-actions {
            margin-top: 1.5rem !important;
            font-size: 80%;

            .row {
              margin: 0 0 0.5rem 0;
            }
          }

          &__supportoffer-popup-button {
            text-align: left;
          }
        }
      }

      &__back {
        &__body {
          padding: 1rem 1rem 2rem 1rem;
          gap: 0;

          h5,
          p {
            font-size: 0.8rem;
          }

          .btn-flex-layout {
            gap: 0 !important;

            .btn {
              scale: 80%
            }
          }
        }
      }
    }
  }

  &--not-public {
    &.support-request-card--default {
      filter: saturate(0);
    }

    &.support-request-card--light {
      filter: brightness(95%);
    }
  }
}