@import "../node_modules/rc-slider/assets/index.css";

.rc-slider {
  &-disabled {
    pointer-events: none;
    background-color: transparent;
  }

  & &-step,
  &-rail,
  &-track {
    height: 8px;
  }

  & &-track {
    background-color: $color-primary;
  }


  &-dot {
    height: 10px;
    width: 10px;
    bottom: -1px;

    &-active {
      display: none;
    }
  }


  & &-handle {
    height: 16px;
    width: 16px;
    border-color: $color-gray-light;
    margin-top: -4px;
    box-shadow: none;

    &:hover {
      border-color: $color-dark;
    }

    &:focus,
    &-click-focused:focus {
      border-color: $color-dark;
    }
  }

  & &-mark {
    top: 22px;
  }

  & &-mark-text {
    color: $color-dark;
    width: inherit;

    &:first-child {
      transform: none !important;
      text-align: left;
    }

    &:last-child {
      transform: translateX(-100%) !important;
      text-align: right;
    }

    &:not(:first-child):not(:last-child) {
      @media (max-width: $md) {
        display: none;
      }
    }
  }
}

/* #region range-slider--all-classes */
//see doc/styles.md add keyword "overwritten-external-styles" for more information

/*
.rc-slider
.rc-slider-rail
.rc-slider-track
.rc-slider-handle
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging
.rc-slider-handle-click-focused:focus
.rc-slider-mark
.rc-slider-mark-text
.rc-slider-mark-text-active
.rc-slider-step
.rc-slider-dot
.rc-slider-dot-active
.rc-slider-dot-reverse
.rc-slider-disabled
.rc-slider-vertical
.rc-slider-tooltip-zoom-down-enter
.rc-slider-tooltip-zoom-down-appear
.rc-slider-tooltip-zoom-down-leave
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active
.rc-slider-tooltip
.rc-slider-tooltip-hidden
.rc-slider-tooltip-placement-top
.rc-slider-tooltip-inner
.rc-slider-tooltip-arrow 
*/

/* #endregion range-slider--all-classes */