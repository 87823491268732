/* #region proposal-selection */
.proposal-selection-item {
  max-width: 625px;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background: white;
  border-radius: $border-radius;
  box-shadow: $box-shadow-default;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  @media screen and (min-width: $md) {
    flex-direction: row;
    align-items: center;
  }

  &__col {
    flex-direction: column;
    display: flex;

    &:first-child {
      flex-grow: 9;
    }

    &:last-child {
      text-align: right;
    }

  }
}

/* #endregion proposal-selection */

/* #region proposal-submission */
.submission-finances {
  .row {
    margin-bottom: 0.5em;
  }

  &__all-sums {
    padding-top: 2em;
    font-weight: 600;

    .row {
      justify-content: flex-end;

    }

    .col {
      &:first-child {
        text-align: right;
      }
    }
  }

  @media screen and (min-width: $md) {
    .submission-finances__total-sum:not(:first-child) {
      border-top: 1px solid $color-gray;
      margin-top: 0.5em !important;
      padding-top: 0.5rem;
    }
  }
}

table.submission-timetable {
  width: 100%;
  empty-cells: show;
  border-collapse: separate;
  border-spacing: 0 5px;

  .first-col {
    width: 10em;
  }

  th,
  td {
    padding: 0.3em;
  }

  th:not(.first-col) {
    text-align: center;
  }

  td.active {
    background-color: $color-primary;
    opacity: 25%;
  }
}

/* #endregion proposal-submission */