// @todo: Lässt sich das umschreiben auf generelle Styles von Forms, da die meisten Forms wie die vom Projektprofil aussehen? Die Klasse wird auch im Feedback-Modul und User-Profile benutzt. Hotfix: Umbenennung in "common-form"
.common-form {

  .form-text {
    color: $color-gray;
    font-size: $font-size-sm;
    font-weight: normal;
  }

  .card-header {
    h3 {
      font-size: $h3-font-size;
      margin-bottom: 1rem;
    }

    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 0.75rem;

      label {
        display: none;
      }

      input {
        width: 50%;
        border: none;
        outline: none;
        font-size: $h3-font-size;
        color: $color-dark;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
    }
  }

  .card-body {
    padding-bottom: 2rem;

    h3 {
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 0.125rem;
    }

    h3+div {
      margin-top: 0.75rem;
    }

    .description {
      color: #595959;
      font-size: 0.875rem;
      font-weight: normal;
      margin-bottom: 0.5rem;
    }

    .form-group {
      margin-bottom: 2.25rem;
    }
  }
}

label {
  font-weight: bold;
  font-size: $font-size-lg;
  margin-bottom: 0.125rem;
}

// added, to make sure, that form-elements, that are horizontally aligned are displayed at the same height => seen in our searchforms, e.g. management: usersearchform
.search-form {
  .form-group:not(.form-check) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}


// checkboxes for filtering certain content information, used in project finances
.checkbox-filter {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 1rem;

  .form-group label {
    font-weight: normal;
    font-size: 1rem;
  }
}

// #region Error + Button Area on forms

// @todo Linda: wie kann man das testen? Norbert: Da es in jedem Formular benutzt wird: vermutlich einfach mal einen Fehler provozieren.
.general-form-error-container {
  color: $danger;
  display: block;
  font-weight: bold;
}

// @todo: lassen sich die nächsten Styles anders lösen (z.B. className="mb-2" im Code)
.form-general-error {
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0rem;
  }
}

// #endregion Error + Button Area on forms


.form-group .input-group {
  padding-bottom: 0.75rem;
}

.icon-navigation.input-group-addon .actionicon {
  margin-left: 0.625rem;
}

.form-control {
  background-color: $color-gray-lighter;
}

.form-control:focus-visible {
  @include focus-visible--forced;
}

.rte-active {
  background-color: $color-gray-lighter;
  outline: 0;
  box-shadow: 0 0 0.2rem 0.2rem adjust_color($color-primary, $alpha: -0.5);
}

.select-form {
  width: fit-content !important;
}

.form-group {
  label {
    font-weight: bold;
    font-size: $font-size-lg;
    margin-bottom: 0.125rem;
  }

  .radio {
    font-weight: normal;
    font-size: 1rem;
    padding-left: 0.2rem;
  }

  .form-control {
    border: none;
    color: $color-dark;
  }

  input[type=number] {
    width: auto;
  }

  textarea {
    height: 5.5rem;
  }

  .form-text {
    margin: 0 0 0.5rem;
  }
}

/* #region checkboxes */
// bootstrap classes

// a container with checkboxes gets 
.form-check {}

// the lable of a checkbox gets this class, which is automatically defined for a <Label check> Tag
.form-check-label {}

// the check itself gets this class
.form-check-input {}

// group of checkboxes
// @todo Linda: stylen!
.form-group-checkbox {

  .form-check-label {
    margin-left: 1.5rem;
  }

  .radio-icon {
    &--chosen {
      color: $color-primary;
    }
  }

  label {
    :hover {
      cursor: pointer;

      svg {
        color: $color-primary;
      }
    }
  }
}

/* #endregion checkboxes */

.form-card .icon-navigation {
  right: 0;
}