/* #region FAQ main component */
.faq {
  display: flex;
  flex-direction: column;
  width: inherit;
  overflow: hidden;

  h1 {
    margin: 0.6875rem auto;
    font-size: 1.75rem;
    font-weight: bold;
  }

  h2 {
    font-family: $font-body;
    font-size: 1.375rem;
    font-weight: bold;
  }

  h3 {
    font-family: $font-body;
    font-size: 1.1875rem !important;
    margin-bottom: 0rem;
    font-weight: normal;
  }

  &__card {
    padding: 0 0.5rem;
    justify-content: center;

    &:focus-visible {
      @include focus-visible--forced;
    }
  }

  &__header {
    padding-top: 1rem;
    position: sticky;
    display: flex;
    flex-direction: column;
    width: inherit;

    &__back-icon {
      position: absolute;
      scale: 1.1;
      margin-top: 0.4rem;
    }

    &__button {
      padding: 0em;
      color: $color-dark;
      background-color: transparent !important;
      width: 30%;
      margin: 0 auto;

      h1 {
        padding: 0em;
        margin: 0em;
      }
    }

    &__line-separator {
      align-self: flex-end;
      margin-top: 0.875rem;
      display: block;
      height: 0.4375em;
      width: 100%;
      background-image: $background-image;
      margin-bottom: 0em;
      border: none;
    }
  }

  &__search {
    position: relative;
    max-width: 100%;
    margin: 1rem 0 2rem 0;

    .form-control {
      background-color: white;
      box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.15);
      padding: 1rem 1rem 1rem 2.6rem;
      border-radius: 9999px;

      &::placeholder {
        opacity: 0.5;
      }

      &:focus-visible {
        box-shadow: $box-shadow-focused;
      }
    }

    &__icon {
      display: inline-flex;
      position: absolute;
      top: 0.4rem;
      left: 0.8rem;
      cursor: pointer;
    }

    &__cancel-icon {
      display: inline-flex;
      position: absolute;
      top: 0.5rem;
      right: 1em;

      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }

    &__results {
      display: block;
      list-style: none;
      padding: 1.5rem;

      &__entry {
        display: flex;
        gap: 0.5rem;
        margin-top: 0.5rem;
        background-color: transparent;

        .btn {
          padding: 0rem;
          text-align: left;
          background-color: transparent;
          font-family: $font-body !important;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.438;
        }
      }
    }

    &__no-entries-found {
      margin-top: 1.2rem;
      text-align: center;
    }
  }

  /* faq consists of 3 levels/layers of navigation: 
  * area-layer shows the main topics with image
  * topic-layer shows collapsable cards of sub-topics with questions
  * entry layer shows the answer to the question 
  */
  &__area-layer,
  &__topic-layer,
  &__entry-layer {
    overflow: auto;
    background-color: inherit;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }

  &__area-layer {

    .card-header {
      padding: 0.5rem;
      justify-content: flex-start;
      align-items: center;

      svg {
        width: 10.200303rem;
        height: 6.15rem;
      }

    }
  }

  &__topic-layer {
    margin-top: 1rem;

    .toggle-icon-toggled {
      transform: rotate(180deg);
    }

    button {
      padding: 0 0 0 1rem;
      text-align: left;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    // collapsable card in topic layer
    &__collapsable-card {
      padding: 1.125rem 0;
    }

    &__question-link {
      font-family: Roboto;
      font-size: 0.875rem;
      line-height: 1.4375rem;
    }
  }

  &__entry-layer {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    &__content {
      flex-grow: 1;
      overflow: auto;
      min-height: 2em;

      /* #region basic html Tags */
      // Styling for HTML-Tags inside of a FAQ-entry
      h3,
      h4,
      ul,
      ol {
        display: block;
        position: relative;
        padding: 0rem;
        margin: 0rem;
      }

      h3,
      h4 {
        padding-top: 1.125rem;
        padding-bottom: 0.5rem;
      }

      li {
        display: list-item;
        padding: 0rem;
      }

      ul {
        list-style-type: circle;
      }

      ol {
        list-style-type: symbols();
      }

      ul,
      ol {
        margin-top: 0.2rem;
        margin-left: 1.5rem;
      }

      p {
        display: block;
        position: relative;
        text-align: left;
        font-size: inherit;
        margin: 0rem;
      }

      h3 {
        font-size: 1rem;
        font-weight: bold;
      }

      h4 {
        font-size: 0.9375rem;
        font-weight: bold;
      }

      /* #endregion basic html Tags */
    }

    &__navigation {
      flex-shrink: 0;
      justify-content: space-between;
      background-color: white;
      flex-wrap: nowrap;
      display: flex;
      padding-top: 1rem;


      &__circles {
        justify-content: center;
        display: flex;
        gap: 0.2rem 0.7rem;
        flex-wrap: wrap;

        .actionicon {
          margin: 0;
        }

        svg {
          height: 0.75rem;
          width: 0.75rem;
          color: $color-gray-lighter;
        }

        .circle-button-active svg {
          color: $color-gray-light;
        }
      }

      .page-navigation-btn {
        scale: 80%;
      }
    }
  }
}

/* #endregion FAQ main component*/

.faq-file-object-not-valid {
  color: $color-red;
  font-family: $font-body;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.625rem;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
}

/* #region FAQ popup*/
// @todo Linda: Was unterscheiden diese Styles zu den normalen Pop-Up Styles? 
.faq-popup {
  &__body {
    padding: 0;
    max-height: 350px;
    overflow-y: auto;
    margin: 2rem 0 0.75rem 0 !important;

    ul {
      padding-inline-start: 1em;
    }
  }

  &__footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    p {
      margin-right: 0.3rem;
    }
  }
}

/* #endregion FAQ popup*/