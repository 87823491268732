@font-face {
    font-family: 'bilobold';
    src: url('/fonts/bilo/bilo-bold.woff2') format('woff2'),
        url('/fonts/bilo/bilo-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bilolight';
    src: url('/fonts/bilo/bilo-light.woff2') format('woff2'),
        url('/fonts/bilo/bilo-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'biloregular';
    src: url('/fonts/bilo/bilo-regular.woff2') format('woff2'),
        url('/fonts/bilo/bilo-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}