/**
 * customizes file, required for the import from index.scss,
   * overwritten by customized builds for customer specific individualizations
   * @see example file at /examples/_custom_styles_example.scss
   * custom-styles for Cottbus are not written in BEM-Konvention! 
   */
@import "fonts/bilo";

/* #region common styles */

html {
  // browser default: 16px  
  font-size: 100%;

  @include media-breakpoint-up(lg) {
    font-size: 120%;
  }
}

/* #endregion common styles */

/* #region header */
header>.row {

  .header-logo {
    width: 165px;
    height: 49px;
    background-image: url("/assets/img/cottbus/logo.svg");
  }
}

/* #endregion header */

/* #region frontpage */

.frontpage {
  background-color: $color-primary;
  background-image: url("/assets/img/cottbus/bg-transparent.svg"), $background-image;
  color: $color-on-primary;

  a:not(.btn, .social-icons, .actionicon) {
    border-bottom: 2px solid $color-on-primary !important;
    border-image: none !important;

    &:hover {
      border-bottom-width: 4px !important;
    }
  }

  h3,
  h3>span {
    color: $color-on-primary !important;
  }

  // different logo on frontpage than on plattform + mobile
  header>.row .header-logo {
    background-image: url("/assets/img/cottbus/logo-frontpage.svg");
    border: none !important;

    @media screen and (min-width: md) {
      background-image: url("/assets/img/cottbus/logo-frontpage.svg");
    }
  }

  // custom background image bottom right
  &:after {
    background-image: none;
  }

  // white navigation
  .icon-navigation {

    .actionicon {

      &:hover:not(.disabled),
      &:active:not(.disabled),
      &:focus:not(.disabled) {
        svg {
          color: $color-dark;
        }
      }
    }
  }

  .frontpage-content .frontpage-title {
    font-family: "bilobold", sans-serif;
  }

  // white footer-links
  .footer-links a:not(col .social-icons) {
    border-bottom: 2px solid $color-on-primary !important;
    color: $color-on-primary;
  }

  .main-container {
    padding: 0;
    max-width: 100vw;
    display: block;
    margin: inherit;
  }

  footer {
    .social-icons {
      a {
        border: none !important;
      }
    }
  }

  .contactform {
    min-width: 250px;
    float: none;

    @include media-breakpoint-up(lg) {
      float: right;
      min-width: 390px;
    }
  }

  .form-control:focus,
  .rte-active {
    box-shadow: 0 0 0.2rem 0.2rem rgb(0, 0, 0);
  }

  .textlength {
    color: $color-dark;
    background-color: transparent;
  }

  .card {
    color: $color-dark;
  }

  // styles of 4 custom frontpages index.tsx, so-gehts.tsx, ueber.tsx & kontakt.tsx
  .frontpage-content {
    text-align: left;
    max-width: inherit;

    .row {
      max-width: 1400px;
      margin: auto;
      padding: 0 0 4rem 0;

      &-narrow {
        max-width: 640px;
      }
    }

    section {
      width: 100%;
      padding: 3rem 1rem 0 1rem;

      @include media-breakpoint-up(lg) {
        padding: 3rem 3rem 0 3rem;
      }
    }

    .section-padding {
      padding: 5rem 3rem;

      .row {
        padding: 0;
      }

      &-tall {
        padding: 3rem 3rem;

        @include media-breakpoint-up(lg) {
          padding: 10rem 3rem;
        }
      }
    }

    .dots {
      margin: auto;
      width: 10px;
      display: block;
      padding: 0;
    }

    img {
      padding: 0.5rem;
    }

    .col {
      width: 100%;
      flex-basis: 200px;

      &-big-breakpoint {
        flex-basis: 500px;
      }
    }

    h1 {
      font-size: 3rem;
      font-family: 'bilobold';
      margin: auto;

      @include media-breakpoint-up(lg) {
        font-size: 3.7rem;
      }
    }

    h2 {
      font-size: 2rem;
      font-family: 'bilobold';
    }

    h3 {
      font-size: 1.75rem;
      font-weight: normal;
      font-family: $font-body;
      color: $color-on-primary !important;
    }

    h4 {
      font-size: 1.2rem;
    }

    h5 {
      font-size: 1.5rem;
      font-family: bilobold;
    }

    .on-white {
      color: $color-dark;
      background-color: #F8F8F8;
      background-image: url("/assets/img/cottbus/bg-transparent-gray.svg");

      h1 {
        color: $color-primary;
      }

      .red {
        color: $color-primary;
      }

      a:not(.btn) {
        border-bottom: 2px solid $color-primary !important;
        border-image: none !important;

        &:hover {
          border-bottom-width: 4px !important;
        }
      }
    }

    p,
    .paragraph {
      font-size: 1.2rem;
      line-height: 1.3;
    }

    .small {
      font-size: 0.75rem;
    }

    .light {
      font-family: 'bilolight';
    }

    .margin-top {
      margin-top: 2rem;

      &-phases {
        margin-top: 0;

        @include media-breakpoint-up(sm) {
          margin-top: 2.5rem;
        }
      }
    }

    .dark {
      color: $color-dark;
    }

    .no-padding {
      padding: 0;
    }

    .img-align {
      text-align: center;
      padding-bottom: 30px;

      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }

    img {
      padding: 0.5rem;
    }

    .offset {
      margin-top: -130px;

      @include media-breakpoint-up(lg) {
        margin-top: -210px;
      }
    }

    .flex {
      display: flex;
      gap: 30px;
    }

    .flex-wrap {
      flex-flow: row wrap;
    }

    .no-flex {
      display: block;
    }

    .middle {
      align-self: center;
    }

    .gap-medium {
      gap: 5px !important;

      @include media-breakpoint-up(lg) {
        gap: 100px !important;
      }
    }

    .gap-tall {
      gap: 180px;
    }

    .display-none {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    ol {
      list-style: none;
      counter-reset: item;

      li {
        counter-increment: item;
        margin-bottom: 5px;
        position: relative;
      }

      li:before {
        position: absolute;
        margin-right: 10px;
        content: counter(item);
        color: $color-primary;
        width: 1.2em;
        text-align: center;
        display: inline-block;
        left: -40px;
        font-family: bilobold;
      }
    }

    ul {
      position: relative;
      left: -19px;
    }
  }
}

/* #endregion frontpage */

/* #region basic layout */

//custom background image bottom right
.subpage {

  // in order to make the background-image transparent it needs to be placed in an extra element
  // @todo: sobald der Multimandant steht, in basic-layout.scss background-image in :after schieben und hier ersetzen
  // dies ist jetzt noch nicht möglich, da sonst andere Mandanten ihr custom background-image verlieren würden
  background-image: none;

  &::after {
    content: "";
    background-image: url('/assets/img/cottbus/grafik.png');
    background-size: cover;
    position: absolute;
    top: 60%;
    left: 55%;
    opacity: 0.3;
    background-size: 20%;
    background-repeat: no-repeat;
    height: 200%;
    width: 200%;
    z-index: -1;
  }
}

/* #endregion basic layout */

/* #region menus */

// white dropdown
.dropdown .btn:hover,
.dropdown .btn:active,
.dropdown .btn:focus {
  color: $color-dark;
}

.dropdown .dropdown-menu {
  border: none;
  background-color: $color-white;
  background-image: none;
  color: $color-dark;
}

.dropdown .dropdown-menu .dropdown-item {
  color: $color-dark;
}

.dropdown .dropdown-menu .dropdown-item :hover,
.dropdown .dropdown-menu .dropdown-item :visited,
.dropdown .dropdown-menu .dropdown-item :active {
  background-color: #eee;
}

.dropdown-menu::before,
.styled-select-container--primary .styled-select__menu::before {
  border-bottom: 13px solid $color-white;
}

.actionicon,
.ordericon,
.non-action-icon {
  scale: 83.33%;
}

/* #endregion menus */

/* #region buttons */

// white text color for red buttons, focus and hover
.primary-btn {
  color: $color-on-primary;

  &:hover,
  &:active,
  &:focus {
    color: $color-on-primary;
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 5px $color-dark;
  }
}

.contactform .btn {
  color: $color-dark;
  background-image: radial-gradient(circle at top left, $color-white, $color-gray-light);
  margin: 0.5rem;

  &:hover {
    background-image: radial-gradient(circle at top left, $color-gray-light, $color-gray-medium);
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 5px $color-dark;
  }
}

/* #endregion buttons */

/* #region media */
.embedded-content {
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 50%;
  }
}

/* #endregion media */

/* #region partnermarket */

.support-request-card {
  padding: 0 0.5rem;

  &:before {
    background-image: radial-gradient(circle at top left, $color-white, $color-white);
  }

  &:after {
    background-image: radial-gradient(circle at top left, $color-white, $color-white);
  }

  &__front {
    &__body {
      padding: 1rem 1rem 2rem 1rem;

      &__supporter-role-simple {
        display: flex;
        gap: 0.5rem;
        align-items: baseline;

        h3 {
          font-size: 0.8rem !important;
          font-family: $font-body !important;
          font-weight: 100;
        }
      }

      h5 {
        font-size: 1rem;
      }

      &__supportoffer-popup-button {
        text-align: left;
      }

      &__content {
        margin-top: 1rem;
      }

      &__data-and-actions {
        margin-top: 1.5rem !important;
        font-size: 80%;

        .row {
          margin: 0 0 0.5rem 0;
        }
      }
    }
  }

  &__back {
    &__body {
      padding: 1rem 1rem 2rem 1rem;
      gap: 0;

      h5,
      p {
        font-size: 0.8rem;
      }

      .btn-flex-layout {
        gap: 0 !important;

        .btn {
          scale: 80%
        }
      }
    }
  }

}

/* #endregion partnermarket */