.project-plan__col {

  @media screen and (max-width: $md) {
    flex-basis: 50%;
  }
}

// @todo: wird wahrscheinlich im Zuge der "Edit-Pages: Header-Bereich harmonisieren" <FCP-816> wegfallen
.project-tasks {
  padding: 0 2.25rem;

  .card-header {
    border-bottom: 1px solid $color-gray-lighter;
  }

  .card-body {
    h4 {
      font-weight: bold;
      display: inline-flex;
      align-items: baseline;
      font-size: 1.125rem;

      svg {
        margin-left: 0.375rem;
      }

      margin-bottom: 0;
    }

    .form-text {
      color: $color-gray;
      font-size: $font-size-sm;
      font-weight: normal;
      margin-bottom: 1.5rem;
    }
  }
}

.task-card-grid-layout {
  margin-top: 1.5rem;
}

.task-card {
  padding: 0 1.25rem;
  min-height: 5rem;
  margin: 0 0 1rem !important;

  .card-body {

    p {
      flex-grow: 1;
      margin: 0;
    }
  }

  form {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
  }

  .form-group {
    margin-bottom: 0;
    flex-grow: 1;
  }

  &--empty {
    margin-bottom: 0;
    min-height: 0;

    .form-btn {
      align-self: center;
    }

    .form-group {
      padding-right: 1rem;
    }
  }
}

.task-popover-container .popover {
  width: 30rem;
  max-width: none;
}

.task-card,
.resource-card {

  .icon-navigation {
    align-items: flex-start;
    float: right;
    flex-wrap: nowrap;
    display: block;
  }

  .input-group-element {
    margin: 0rem;
    margin-left: 0.4rem;
    margin-left: 0.4rem;
  }
}

.work-package-card {
  padding: 0;
  width: 25rem;

  &__description {
    font-size: $font-size-sm;
    font-style: italic;
    color: $color-gray;
    margin: 1.5rem;
  }

  .card-header {
    display: flex;
    padding: 2rem 1rem;
  }

  .task-card {
    margin: 1rem !important;
  }

  .card-footer {
    background: $color-gray-lighter;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    span {
      color: $color-gray;
      margin-left: 0.5rem;
      font-size: $font-size-sm;
    }
  }
}

// @todo Linda: Prüfen. Dafür mit backend verbinden.
.challenge-concretizations-list {
  li+li {
    margin-top: 2.25rem;
  }
}