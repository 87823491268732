@charset "UTF-8";

/*
Styles, die von Coder benötigt werden/angelegt werden, aber noch nicht von Linda eingearbeitet wurden
*/

/* #region norbert */

/* #endregion norbert */

/* #region frieder */

/* #endregion frieder */

/* #region linda */

/* #endregion linda */

/* #region phillipp */

.discussion-card__header--support-request {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__icon {
    margin: auto 2rem auto 0rem;
  }
}

// The SupportRequest is shown in modal and the padding and margin should be zero,
// because only the SupportRequestCard should be visible in the modal.
.transparentModal {
  .card {
    margin: 0rem;
  }

  .modal-content {
    padding: 0rem;

    .modal-body {
      padding: 0rem;
    }
  }
}

// the idea is to place the order filter and the search field next to each other
.partner-market-search-container {
  margin-top: 2rem;
  display: flex;
  align-items: center;

  // overwriting this css class, which is defined in marketplace.scss
  // @todo Linda move marketplace-search-container in universal styles or in extra usecase file: "searchfield"?
  .marketplace-search-container {
    margin: 0rem 1rem 0rem 1rem;
    flex-grow: 6;
  }

  .sortby-filter {
    flex-grow: 1;
  }
}

// used in the OrderByComponent
.sortby-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

/* #endregion phillipp */