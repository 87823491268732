/* constants, required for the import within constants.module..scss,
   to overwrite default constants for customization */

$color-primary: #e40134;
$color-primary-gradient: #C3002C; // update/replace, if you want to have a gradiant in the color
$color-primary-light: #fef7f8;
// color variables need to be copied here from constants.module.scss for import order (when needed for variables below), to be marked as copy
$color-white: #ffffff; // copy of constants.module.scss
$color-gray-light: #D4D4D4; // copy of constants.module.scss
$color-on-primary: $color-white;

$color-secondary: $color-white;
$color-secondary-gradient-contrast-color: $color-gray-light;
$gradient-of-color-secondary: radial-gradient(circle at top left, $color-secondary, $color-secondary-gradient-contrast-color) !default;

$font-heading: "bilobold",
  sans-serif !default;
$font-body: "biloregular",
  sans-serif !default;
$font-cursive: "Caveat",
  cursive !default;

//default gradient
$background-image: radial-gradient(circle at top left, $color-primary, $color-primary-gradient);

// favicon
$faviconUrl: url("/favicon.ico");