// autocomplete doesn't come with own styles, but predefined style class-names: https://github.com/moroshko/react-autosuggest#theme-prop, that's why there won't be a list of all class names 
// see doc/styles.md "overwritten-external-styles" for more information
// @todo Linda: diese Styles finden anscheinen keine Anwendung. Ticket ist angelegt: FCP-1102

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 100%;
  width: 100%;
  box-shadow: 0 0.1rem 0.2rem 0.2rem adjust_color($color-primary, $alpha: -0.5);
  background-color: $color-white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px 10px;
  font-weight: 600;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $color-gray-lighter;
}